import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import './polyfill'
import App from './App';
import reportWebVitals from './reportWebVitals';

import Admin from './pages/Admin';
import WhitelistComponent from './pages/WhitelistComponent';
import FreeMintTeamComponent from './pages/FreeMintTeamComponent';
import FreeMintComponent from './pages/FreeMintComponent';
import LoginComponent from './pages/Login';
import UserComponent from './pages/UserComponent';
import Mint from './pages/Mint'
import TermsComponent from './pages/TermsComponent'
import GrowlieList from './pages/GrowlieList'
import MemberComponent from './pages/MemberComponent'
import EditMetadata from './pages/EditMetadata'
import LoadingComponent from './pages/LoadingComponent'

ReactDOM.render(
    <Router>
      <Routes>
        <Route exact path="/" element={ <App /> } />
        {/* <Route exact path="/mint" element={ <Mint />} /> */}
        <Route exact path="/dashboard" element={ <Admin/> } />
        <Route exact path="/whitelist" element={ <WhitelistComponent/> } />
         {/* <Route exact path="/free_mint_team" element={ <FreeMintTeamComponent/> } />  */}
        <Route exact path="/free_mint" element={ <FreeMintComponent/> } />
        <Route exact path="/admin_login" element={ <LoginComponent/> } />
        <Route exact path="/user" element={ <UserComponent/> } /> 
        <Route exact path="/terms" element={ <TermsComponent/> } />
        <Route exact path="/growlielist" element={ <GrowlieList />} />
        <Route exact path="/members" element={ <MemberComponent />} />
        <Route exact path="/edit_metadata" element={ <EditMetadata />} />
        <Route exact path="/token" element={ <LoadingComponent />} />
      </Routes>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
