import React from 'react'

export default function TermsComponent() {

    return (
        <React.Fragment>
            <header>

                <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="/"><img src="assets/template/img/logoBG.png" className="logo" alt="logo" /></a>
                    <ul id="social" className="navbar-nav ml-auto" style={{ flexDirection: 'inherit' }}>
                        <li className="nav-item">
                            <a className="nav-link" href="https://twitter.com/BattleGrowlies"><i className="fa-brands fa-twitter" style={{ color: '#FA4EFC', fontSize: '40px' }}></i></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://discord.com/invite/battlegrowlies"><i className="fa-brands fa-discord" style={{ color: '#FA4EFC', fontSize: '40px' }}></i></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#"><img src="assets/img/Logomark-Blue.svg" height="30" alt="opensea" style={{ width: '40px' }} /></a>
                        </li>
                    </ul>

                </nav>
            </header>
            <div className='terms' style={{ paddingLeft: '100px', paddingRight: '100px' }}>

                <h2 align="center" style={{ textAlign: 'center', color: '#1e59e9' }}>Terms &amp; Conditions</h2>

                <p align="center" style={{ textAlign: 'center' }}><b><span
                    style={{ fontSize: '14pt', fontFamily: 'Optima' }}>&nbsp;</span></b></p>

                <p ><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Last Updated: March 14, 2022</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Disclaimer</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    The following terms and conditions set out the legal basis for our activity, service and Artworks (“Service” or “Services”),
                    being essential to read and understand these “Terms and Conditions” carefully before purchasing or using our Services,
                    website and Artworks. These Terms & Conditions of Service are a legal agreement between CARNADA VISUAL
                    (“CARNADA VISUAL”, “BATTLE GROWLIES NFTs”, “we “, “us” or “our”) and you ("you" or “your”).
                    By visiting our site and/or purchasing something from us,
                    you engage in our Service and agree to be bound by these Terms & Conditions.
                </span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>About us</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    BATTLE GROWLIES NFTs is a generative collection of digital Artworks, which are Non-Fungible Tokens (“NFTs”)
                    housing and running on the Ethereum Network, and a website operated by CARNADA VISUAL.</span></p>


                <p style={{ textAlign: 'Optima' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    Our Artworks are created by a team of artists and developers, where the rights are reserved to CARNADA VISUAL,
                    including all the intellectual property rights and copyrights surrounding BATTLE GROWLIES NFTs, names, logos,
                    trademarks, the website, the design of the User Interface and User Experience (“UX”), the smart contract code,
                    fonts, and all the generative traits used of the BATTLE GROWLIES NFTs.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Rights reserved</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    CARNADA VISUAL reserves the exclusive rights to use official BATTLE GROWLIES NFTs logos, texts, and other content associated
                    with this project, our website, social media and channels for any future advertising or promotion purposes.</span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    Furthermore, CARNADA VISUAL reserves the right to update, change or replace any part of these Terms & Conditions,
                    without prior written notice at any time, which we may do for reasons that include, but are not limited to,
                    complying with changes to the law or reflecting enhancements. It is your responsibility to periodically review
                    these Terms & Conditions to stay informed of updates. Your use of the Service after the last modifications and updates
                    posted shall constitute your acceptance and agreement to those changes. If you object to the changes, your recourse shall be to cease using the Service.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Use of the website</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    This website (the "Site" or also the “Service,”) is only an interface allowing participants to buy and/or exchange digital collectibles and Artwork.
                    Users and Collector are solely and entirely responsible for the safety and management of their own private assets,
                    which include but are not limited to their own private Ethereum Wallets and validating all transactions and contracts
                    generated by this website before approval and prior to and after purchases. Furthermore,
                    as the BATTLE GROWLIES NFTs smart contract runs on the Ethereum Network, there is no ability to undo, reverse, or restore any transactions.</span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    This website and its connected Services are provided “as is” and “as available” without warranty of any kind, either express or implied.
                    Your use of the Services is at your sole risk. In addition, while CARNADA VISUAL attempts to provide a good user experience,
                    we cannot and do not represent or warrant that the Services will always be secure or error-free or that the Services will always function without delays,
                    disruptions, or imperfections. All these disclaimers shall apply to the extent permitted by applicable law. By using this website,
                    you are accepting sole responsibility for any and all transactions involving BATTLE GROWLIES NFTs digital collectibles.</span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    By using or accessing this website you agree (i) that you are the age of majority in your jurisdiction, state or province of residence or over,
                    that you have read, understood, and accept to be bound by these Terms & Conditions; (ii) that you have the legal capacity to legally bind yourself
                    under these Terms & Conditions under applicable law; and (iii) Users certify through purchase that they understand that,
                    as the BATTLE GROWLIES NFTs contract runs on the Ethereum Network and is bound by their system and terms, there is no ability to undo, reverse, or restore any transactions.</span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    By visiting the website of our Artwork, in particular www.opensea.io, you also engage and agree to be bound by their Terms & Conditions.
                </span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    This website is for informational purposes and serves as a binding contract for purchasers of BATTLE GROWLIES NFTs.
                </span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    You may not use our Artwork, Intellectual Property, logo and/or Services for any illegal or unauthorized purpose nor may you, in the use of the Service,
                    violate any laws in your jurisdiction (including but not limited to copyright laws) or any other jurisdiction.
                </span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Use of the Service</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    Subject to your compliance with these Terms & Conditions, CARNADA VISUAL grants you a limited, revocable, non-exclusive, non-transferable,
                    non-sublicensable license to use and access the Service. You agree not to (and not to attempt to) (i) use the Service for any use or purpose
                    other than as expressly permitted by these Terms & Conditions; (ii) copy, adapt, modify, prepare derivative works based upon, distribute,
                    license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, attempt to discover any source code,
                    reverse engineer, decompile, disassemble, or otherwise exploit the Service or any portion of the Service, except as expressly permitted in these Terms & Conditions;
                    or (iii) use data mining, robots, spiders, or similar data gathering and extraction tools on the Service. No licenses or rights are granted to you
                    by implication or otherwise under any intellectual property rights owned or controlled by CARNADA VISUAL or its licensors,
                    except for the permissions and rights expressly granted in these Terms & Conditions.</span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    CARNADA VISUAL reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.
                    CARNADA VISUAL reserves the right to refuse any user access to the Services without notice for any reason, including but not limited to a violation of the Terms.
                    If you violate these Terms & Conditions, CARNADA VISUAL reserves the right to issue you a warning regarding the violation or immediately terminate or suspend any or all uses of the Service.
                    You agree that CARNADA VISUAL need not provide you notice before terminating or suspending your use, but it may do so.
                </span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Intellectual Property &amp; Copyright </span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    All rights, title and interest in and to all Artwork that is part of the Service (including, but not limited to, designs, text, graphics, pictures,
                    video, information, applications, logo, software, music, sound and other files, and their selection and arrangement),
                    are owned by CARNADA VISUAL and/or its third-party licensors. You acknowledge and agree that you shall not acquire any ownership rights whatsoever
                    by downloading Artwork or by purchasing any Virtual Currency or Virtual Goods. You agree that you shall not modify, copy, distribute, frame, reproduce,
                    republish, download, scrape, display, post, transmit, or sell in any form or by any means, in whole or in part, or otherwise exploit the Artwork without our express prior written permission.
                    You acknowledge that you do not acquire any ownership rights by using the Service or by accessing any Artwork posted on the Service by CARNADA VISUAL, or any derivative works thereof.
                    All rights not expressly granted by these Terms & Conditions are totally reserved by CARNADA VISUAL, and no license is granted hereunder by estoppel, implication or otherwise.
                </span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    When you purchase your BATTLE GROWLIES NFT, you only own the NFT. The ownership is transferred to you on the Ethereum blockchain for that individual piece of Art combination of traits and number.
                    Ownership of the NFT is ruled by the smart contract and the Ethereum Network terms. We, CARNADA VISUAL, have no ability to alter, freeze, seize or modify the ownership of any BATTLE GROWLIES NFT.
                </span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    CARNADA VISUAL respects the intellectual property of others, and we ask our Users to do the same.
                    If you believe that your work has been copied in a way that constitutes copyright infringement,
                    or that your intellectual property rights have been otherwise violated, you should notify CARNADA VISUAL
                    of your infringement claim in accordance with the procedure set forth below. CARNADA VISUAL will process
                    and investigate notices of alleged infringement. A notification of claimed copyright infringement should be emailed at:
                </span><span ><a href="mailto:info@carnadavisual.com"><span
                    style={{ fontSize: '14pt', fontFamily: 'Optima', background: 'yellow' }}> info@carnadavisual.com</span></a></span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    To be effective, the notification must be in writing and contain the following information:
                </span></p>


                <p className="MsoListParagraphCxSpFirst" style={{ marginLeft: '54.0pt', textAlign: 'justify', textIndent: '-36.0pt' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>(i)<span
                    style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>an electronic
                    or physical signature of the person authorized to act on behalf of the owner of
                    the copyright or other intellectual property interest.</span></p>

                <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: '54.0pt', textAlign: 'justify', textIndent: '-36.0pt' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>(ii)<span
                    style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>a description of
                    the copyrighted work or other intellectual property that you claim has been infringed.</span></p>

                <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: '54.0pt', textAlign: 'justify', textIndent: '-36.0pt' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>(iii)<span
                    style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>a description
                    of where the material that you claim is infringing is located on the Service,
                    with enough detail that we may find it on the Service.</span></p>

                <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: '54.0pt', textAlign: 'justify', textIndent: '-36.0pt' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>(iv)<span
                    style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>your address,
                    telephone number, and email address.</span></p>

                <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: '54.0pt', textAlign: 'justify', textIndent: '-36.0pt' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>(v)<span
                    style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>a statement by
                    you that you have a good faith belief that the disputed use is not authorized
                    by the copyright or intellectual property owner, its agent, or the law.</span></p>

                <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: '54.0pt', textAlign: 'justify', textIndent: '-36.0pt' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>(vi)<span
                    style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>a statement by
                    you, made under penalty of perjury, that the above information in your Notice
                    is accurate and that you are the copyright or intellectual property owner or
                    authorized to act on the copyright or intellectual property owner’s behalf; and</span></p>

                <p className="MsoListParagraphCxSpLast" style={{ marginLeft: '54.0pt', textAlign: 'justify', textIndent: '-36.0pt' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>(vii)<span
                    style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>If you submit a
                    notice of infringement that knowingly materially misrepresents that any
                    Content, information, or communication on the Services is infringing upon a
                    copyright, you may be held liable for damages and attorneys’ fees.</span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    Other than the rights to the Art, nothing gives you any
                    rights to any other trademarks or other intellectual property rights belonging
                    to CARNADA VISUAL including, without limitation CARNADA VISUAL, BATTLE GROWLIES
                    NFTs and the associated logos. All of these rights are expressly reserved in
                    the name of CARNADA VISUAL.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Personal Use</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    Derived from the enforcement of these Terms & Conditions and its compliance, CARNADA VISUAL allows you a worldwide,
                    royalty-free license to use, copy and display the purchased Artwork, along with any extensions that you choose to create or use,
                    solely and limited by the following purposes: (i) for your own personal, non commercial use; (ii) as part of a marketplace that
                    allows the purchase and sale of your BATTLE GROWLIES NFT, provided that the marketplace cryptographically verifies each
                    BATTLE GROWLIES NFT owner’s rights to display the Art for their BATTLE GROWLIES NFT to ensure that only the actual owner can display the Art;
                    or (iii) as part of a third party website or application that permits the inclusion, involvement or participation of your BATTLE GROWLIES NFT,
                    provided that the website or the application cryptographically verifies each BATTLE GROWLIES  NFT owner’s right to display the Art for their BATTLE GROWLIES NFT,
                    to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the actual owner of the BATTLE GROWLIES NFT leave the website or application.
                </span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Commercial Use</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    Derived from the enforcement of these Terms & Conditions and its compliance, CARNADA VISUAL grants you a worldwide license to (i) use,
                    copy and display the purchased Art for the purpose of creating highly transformative derivative works based on the Art ("Commercial Use"),
                    for the avoidance of doubt, the derivative work may not be used for commercial purposes if it is identical or the same as the original purchased Artwork
                    (ii) own or operate a third party website or application that permits the inclusion, involvement, or participation of BATTLE GROWLIES NFT generally,
                    provided that the third party website or application cryptographically verifies each BATTLE GROWLIES NFT owner’s rights to display the Art for their
                    BATTLE GROWLIES NFT to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased BATTLE GROWLIES NFT
                    leaves the website/application. If you wish to give any other commercial use to your NTF, you must request an express authorization to CARNADA VISUAL by mail at:  </span>
                    <span ><a href="mailto:info@carnadavisual.com" style={{ fontFamily: 'Optima', background: 'yellow' }}>info@carnadavisual.com</a></span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    Further, CARNADA VISUAL reserves the right to use any character or Artwork, including print or digital advertising,
                    or any purely creative media (including short film, anime, among others) in support of the BATTLE GROWLIES NFTs community and message.</span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    You cannot use the Artwork in connection with images of hatred, violence or any other inappropriate behavior or prohibited by any applicable law.
                    The License granted in above only applies to the extent that you continue to own the relevant NFT. If at any time you trade, donate, giveaway,
                    transfer or otherwise dispose of your NFT for any reason, the license granted above will immediately expire, without notice,
                    and you will have no further right in or to the Artwork of this NFT.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>No Guarantees or Future Promises</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    You agree that your purchase of a BATTLE GROWLIES NFT from our project is all you are guaranteed to receive with your purchase.
                    Any future potential airdops, community gatherings, or other benefits are ancillary to this purchase and not to be taken into consideration with your purchase.
                    You agree that you are not relying on any future commitments by BATTLE GROWLIES NFT in using this site and participating in our NFT launch.
                    You agree that BATTLE GROWLIES NFT are not intended as investments. We make absolutely no promise or guarantee that these NFTs will be worth anything.
                    You understand that they have no inherent monetary value.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Taxes</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>You are entirely responsible for any tax liability which
                    may arise from minting or reselling your BATTLE GROWLIES NFT.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Feedback</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    We appreciate hearing from our Users and welcome your comments regarding the Service.
                    If you choose to provide feedback, comments or suggestions for improvements to the Service or otherwise
                    (in written or oral form) (“Feedback”), you represent and warrant that (i) you have the right to disclose the Feedback;
                    (ii) the Feedback does not violate the rights of any other person or entity; and (iii) your Feedback does not contain
                    the confidential or proprietary information of any third party or parties.</span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    By sending us any Feedback, you further (i) agree that we are under no obligation of confidentiality,
                    express or implied, with respect to the Feedback; (ii) acknowledge that we may have something similar
                    to the Feedback already under consideration or in development; (iii) grant us an irrevocable, non-exclusive,
                    royalty-free, perpetual, worldwide license to use, modify, prepare derivative works from, publish,
                    distribute and sublicense the Feedback; and (iv) irrevocably waive, and cause to be waived,
                    against CARNADA VISUAL and its Users any claims and assertions of any moral rights contained in such Feedback.
                    This Feedback section shall survive any termination of your account or the Services.
                </span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>All rights in this section are granted without the need for
                    additional compensation of any sort to you.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Indemnification</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    You agree to indemnify and hold CARNADA VISUAL, harmless from and against any loss, liability,
                    claim, demand, damages, costs and expenses, including reasonable attorney's fees,
                    arising out of or in connection with (i) your use of the Service;
                    (ii) your violation of any term of these Terms & Conditions; or (iii) your violation of any right,
                    including without limitation any copyright, property, or privacy right, among others.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Limitations of Liability for Gas, Failed Transactions,
                    Smart Contract Bugs</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    You agree to hold us harmless for any losses you may incur
                    as a consequence of minting your NFT. These potential losses include any gas
                    fees for failed transactions, any excessive gas fees charged due to website or
                    smart contract bugs, and any loss of your NFT due to website or smart contract
                    bugs.</span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    To the maximum extent permitted by law, in no event will CARNADA
                    VISUAL, be liable to you or to any third person for any consequential,
                    incidental, special, punitive or other indirect damages, including any lost
                    profits or lost data, arising from your use of the Service or other materials
                    on, accessed through or downloaded from the Service, whether based on warranty,
                    contract, tort, or any other legal theory, and whether or not CARNADA VISUAL
                    has been advised of the possibility of these damages. CARNADA VISUAL shall not
                    be liable to you for more than the greater of (a) the amount you have paid to
                    us in accordance with these terms in the three (3) months immediately preceding
                    the date on which you first assert a claim or (b) $100. </span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Class Action Waiver</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    You agree to waive any class action status, and any legal
                    dispute around the BATTLE GROWLIES NFTs project which you may choose to bring
                    can only be done on an individual basis.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Privacy Policy</span></b></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    BATTLE GROWLIES NFT will not collect any cookies, IP
                    addresses, or user data in connection with your use of the website and app.
                    This is subject to change. Furthermore, you understand that the Ethereum
                    blockchain Network is a public blockchain and all of your transaction history
                    initiated through the website will be made public. You also understand that BATTLE
                    GROWLIES NFT may work with third-party apps that collect data, such as Discord
                    or Collab.Land for BATTLE GROWLIES NFT Holder verifications or giveaways.
                    However, if BATTLE GROWLIES NFT aims to launch products (e.g. merch store) in
                    the future, this will require us to collect and store user data. In that event,
                    this provision is subject to change.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>Communications</span></b></p>

                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    You agree to receive communications from us electronically,
                    such as email, text, or mobile push notices, or notices and messages on the
                    Service. For any direct marketing messages, we will ensure that we obtain your
                    consent first, and also make it easy for you to opt out — we don’t want to send
                    you messages you don’t want.</span></p>


                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>By using the Service or providing information to us, you
                    agree that we may communicate with you electronically regarding security,
                    privacy, and administrative issues relating to your use of the Service, and
                    that all agreements, notices, disclosures, and other communications that CARNADA
                    VISUAL provides to you electronically satisfy any legal requirements that such
                    communications be in writing.</span></p>


                <p style={{ textAlign: 'justify' }}><b><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>User disputes</span></b></p>

                <p style={{ textAlign: 'justify' }}><span style={{ fontSize: '14pt', fontFamily: 'Optima' }}>
                    You are solely responsible for your interaction with other Users
                    of the Service and other parties that you come in contact with through the
                    Service. CARNADA VISUAL hereby disclaims any and all liability to you or any
                    third party relating to your use of the Service. CARNADA VISUAL reserves the
                    right, but has no obligation, to manage disputes between you and other Users of
                    the Service.</span></p>


                <p align="center" style={{ textAlign: 'center' }}><b><i><span
                    style={{ fontSize: '14pt', fontFamily: 'Optima' }}>All Rights Reserved </span></i></b><b><i><span
                        style={{ fontSize: '14pt', fontFamily: 'Symbol' }}>Ó</span></i></b><b><i><span
                            style={{ fontSize: '14pt', fontFamily: 'Optima' }}> Carnada Visual, USA &amp; Mexico,
                            2022.</span></i></b></p>

            </div>
        </React.Fragment>
    )
}