import React, { useEffect, useState } from 'react'
import Config from '../../utils/Config.js';
import axios from "axios";
import Web3 from 'web3'

const RegisterManual = ({ setPreWhitelist, setErrorMessage, setMessage }) => {
    const [address, setAddress] = useState('')

    const onRegister = () => {
        if (address === '') {
          
                setErrorMessage('Enter your wallet')
        
        }
        else {
            let wallet = address.replace(/\s+/g, '');

            const data = {
                prewhitelist: true,
                wallet: wallet.toLowerCase()
            }

            if (Web3.utils.isAddress(wallet)) {
                axios.post(Config.apiURL + "/wallet_add/", data).then((res) => {
                    setPreWhitelist(true)
                    setMessage('Add successfully')
                }).catch(error => {
                    if(error.response.data.wallet){
                        setPreWhitelist(true)
                        setMessage('Already on prewhitelist')
                    }
                    setErrorMessage(error.response.data.errors);
                });
            }
            else{
                setErrorMessage('Invalid Wallet')
            }
        }
    }

    return (
        <div className="row banner" style={{ margin: 0, zIndex: 100 }}>
            <img src="assets/template/img/registro.png" className="img-fluid m-auto" alt="banner" />

            <div className="waiting">


                <div >
                    <p className='text-waiting' style={{ fontFamily: 'Bubble', fontSize: '25px', textTransform: 'none' }}>Get on the Growlie-list (wl)</p>

                    
                    <input className='discord'  placeholder='Enter Wallet' value={address} onChange={e => setAddress(e.target.value)} />

                </div>
                <button className="mint boxShadow" onClick={onRegister}>SUBMIT</button>




            </div>
        </div>
    )
}

export default RegisterManual
