import React from 'react';
import {
    Button, Input, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from "@chakra-ui/react";
import contracts from '../../contracts';
import  Web3  from 'web3';
import { Formik, Form, Field } from 'formik';




export default function ModalTransfer({ isOpen, onClose, setErrorMessage, getContractBalance, setMessage}) {

    function validateNoEmptyWallet(value) {
        let error;
        if (!value)
            error = 'Is required'
        if(!Web3.utils.isAddress(value.replace(/\s+/g, '')))        
            error = 'Invalid address'

        return error
    }

    function validateNoEmpty(value) {
        let error;
        if (!value)
            error = 'Is required'
    
        return error
    }



    return (
        <React.Fragment>
            <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Transfer</ModalHeader>
                    <ModalCloseButton />
                    <Formik
                        initialValues={{ wallet: '', amount: '' }}
                        onSubmit={async (values, actions) => {

                            if (window.ethereum) {
                                if (window.ethereum.isMetaMask) {
                                    const web3 = new Web3(window.ethereum)
                                    const { BG } = contracts

                                    try {
                                        const contract = await new web3.eth.Contract(BG.ABI, BG.Address)
                                        const transfer = await contract.methods.withdraw(values.wallet.replace(/\s+/g, ''), values.amount).send({ from: window.ethereum._state.accounts[0] })
                                        getContractBalance()

                                        console.log(transfer)
                                        actions.setSubmitting(false)

                                        setMessage('Balance transferred successfully')
                                        onClose()
                                    } catch (error) {
                                        actions.setSubmitting(false)
                                        setErrorMessage(error);
                                    }

                                }
                            }

                        }}
                    >
                        {(props) => (
                            <Form>
                                <ModalBody>
                                    
                                    <Field name='amount' validate={validateNoEmpty}>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.amount && form.touched.amount} mt={5} isRequired>
                                                <FormLabel htmlFor='amount'>Amount</FormLabel>
                                                <Input {...field} id='amount' placeholder='amount' type="number" step="0.01" />
                                                <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>


                                    <Field name='wallet' validate={validateNoEmptyWallet} >
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.wallet && form.touched.wallet} mt={5} isRequired>
                                                <FormLabel htmlFor='wallet' >Wallet</FormLabel>
                                                <Input {...field} id='walletA' placeholder='wallet' />
                                                <FormErrorMessage>{form.errors.wallet}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>

                                    <Button
                                        colorScheme='teal'
                                        isLoading={props.isSubmitting}
                                        type='submit'
                                    >
                                        Submit
                                    </Button>
                                </ModalFooter>

                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </React.Fragment>
    );
}