import React from 'react';
import {
    Button, Input, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Form, Field } from 'formik';
import Config from '../../utils/Config.js';
import axios from "axios";



export default function ModalCustom({ isOpen, onClose,setErrorMessage, setMessage}) {


    function validateNoEmpty(value) {
        let error;
        if (!value)
            error = 'Is required'
       
        return error
    }

    function validateEmail(value) {
        let error;
        if (!value)
            error = 'Is required'

        if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
        ) {
            error = 'Invalid email address';

        }

        return error
    }

    const validateConfirmPassword = (pass, value) => {

        let error = "";
        if (pass && value) {
          if (pass !== value) {
            error = "Password not matched";
          }
        }
        return error;
      };


    return (
        <React.Fragment>
            <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add User</ModalHeader>
                    <ModalCloseButton />
                    <Formik
                        initialValues={{ name: '', email: '', user: '', password:'',confirm_password:'' }}
                        onSubmit={(values, actions) => {
                            let data = {
                                name: values.name,
                                email: values.email,
                                username: values.user,
                                password: values.password
                            }
                          
                            const ConfigHeader = {
                                headers: {
                                       'Authorization': "JWT " + localStorage.getItem('access_token'),
                                       'Content-Type': 'application/json',
                                       'accept': 'application/json'
                                   }
                                 }
                                 
                            axios.post(Config.apiURL + "/user/", data, ConfigHeader).then(() => {
                                
                                actions.setSubmitting(false)
                                onClose()
                                setMessage('Added successfully')
                                window.location.reload();
                            }).catch(error => {
                                actions.setSubmitting(false)
                                setErrorMessage(error.response.data.errors);
                            });
                        }}
                    >
                        {(props) => (
                            <Form>
                                <ModalBody>

                                    <Field name='email' validate={validateEmail}>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.email && form.touched.email} mt={5} isRequired>
                                                <FormLabel htmlFor='email'>Email</FormLabel>
                                                <Input {...field} id='email'  />
                                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>


                                    <Field name='user' validate={validateNoEmpty} >
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.user && form.touched.user} mt={5} isRequired>
                                                <FormLabel htmlFor='user' >Username</FormLabel>
                                                <Input {...field} id='user' />
                                                <FormErrorMessage>{form.errors.user}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name='name' >
                                        {({ field, form }) => (
                                            <FormControl mt={5} >
                                                <FormLabel htmlFor='name' >Name</FormLabel>
                                                <Input {...field} id='name'  />
                                                
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name='password' validate={validateNoEmpty} >
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.password && form.touched.password} mt={5} isRequired>
                                                <FormLabel htmlFor='password' >Password</FormLabel>
                                                <Input {...field} id='password' type='password'/>
                                                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name='confirm_password' validate={value => validateConfirmPassword(props.values.password, value) } >
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.confirm_password && form.touched.confirm_password} mt={5} isRequired>
                                                <FormLabel htmlFor='confirm_password' >Confirm Password</FormLabel>
                                                <Input {...field} id='confirm_password' type='password'  />
                                                <FormErrorMessage>{form.errors.confirm_password}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>

                                    <Button
                                        colorScheme='teal'
                                        isLoading={props.isSubmitting}
                                        type='submit'
                                    >
                                        Submit
                                    </Button>
                                </ModalFooter>

                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </React.Fragment>
    );
}