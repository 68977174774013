import React, { useEffect, useState } from 'react'


const Waiting = () => {

    return (
        <div className="row banner" style={{ margin: 0, zIndex: 100 }}>
            <img src="assets/template/img/presale.png" className="img-fluid m-auto" alt="banner" />

            <div className="waiting">
                <div className="qty">
                    <img className='img-waiting' src="assets/template/img/tooth.png"  alt="tooth-menu" />
                    <span className='text-waiting' >Waiting for confirmation...</span>
                </div>

            </div>
        </div>
    )
}

export default Waiting
