import React, { useEffect, useState } from 'react'


const Minted = () => {

    return (
        <div className="row banner" style={{ margin: 0, zIndex: 100 }}>
            <img src="assets/template/img/presale.png" className="img-fluid m-auto" alt="banner" />

            <div className="waiting">
                <div className="qty">
                    <img className='img-whitelist' src="assets/template/img/tooth.png" alt="tooth-menu" />
                    <p className='text-waiting' >Congratulations!</p>
                    <p className='text-waiting' style={{ fontSize: '18px', textTransform: 'none', fontFamily: 'Averta' }} >
                        Check your minted Growlie on Opensea!
                    </p>
                </div>

            </div>
        </div>
    )
}

export default Minted
