class Config{
   //static apiURL = "https://backend.battlegrowlies.com/api"
    // static apiURL = "http://localhost:8000/api"

    //static loginUrl = "https://backend.battlegrowlies.com/api/gettoken/";
    //static walletlistUrl = "https://backend.battlegrowlies.com/api/walletlist/";
    //static loginUrl2 = "https://backend.battlegrowlies.com/api/gettoken/";
    static homeURL = "/dashboard";
    //static API_URL = "https://backend.battlegrowlies.com/api/walletlist/";

    //DEV
   static apiURL = "https://backdp.softok2.mx/api";
   static loginUrl = "https://backdp.softok2.mx/api/gettoken/";
   static walletlistUrl = "https://backdp.softok2.mx/api/walletlist/";
   static loginUrl2 = "https://backdp.softok2.mx/api/gettoken/";
   static API_URL = "https://backdp.softok2.mx/api/walletlist/";


    static sidebarItem = [
        {"index":"0","title":"Home","url":"/dashboard"}
    ]
}

export default Config;
