import React, {useState, useEffect} from 'react';
import LoadingToken from './LoadingToken';
import { ChakraProvider } from '@chakra-ui/react'


export default function LoadingComponent() {

    return (
        <React.Fragment>
            <ChakraProvider>
                <LoadingToken />
            </ChakraProvider>
        </React.Fragment>
    )
}
