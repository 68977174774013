import React, { useEffect, useState } from 'react'
import contracts from '../../contracts'
import Web3 from 'web3'
import axios from 'axios'
import Config from '../../utils/Config'

const Mintt = ({ whitelist, totalMinted, setTotalMinted, freeMint, freeMinted, setFreeMinted, isPresale, setWaiting, totalSupply,
    setTotalSupply }) => {

    const [cantNFT, setCantNFT] = useState(1)
    const [total, setTotal] = useState(isPresale ? 3 : totalSupply >= 9990 ? (10000 - totalSupply) : 10)
    const [disabledMint, setDisabledMint] = useState(parseInt(totalMinted) >= total ? true : false)
    const [disabledFreeMint, setDisabledFreeMint] = useState(freeMinted)

    useEffect(() => {
        setTotal(isPresale ? 3 : totalSupply >= 9990 ? (10000 - totalSupply) : 10)
    }, [totalSupply]);
     

    useEffect(() => {
        setDisabledMint(parseInt(totalMinted) >= total)
    }, [totalMinted]);

    useEffect(() => {
        setDisabledFreeMint(freeMinted)
    }, [freeMinted]);

    const sumNFT = () => {
        let intNFTS = parseInt(totalMinted)
        if ((cantNFT + intNFTS) < total)
            setCantNFT(cantNFT + 1)
    }

    const minNFT = () => {
        if (cantNFT > 1)
            setCantNFT(cantNFT - 1)
    }

    const mintLootBox = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                const web3 = new Web3(window.ethereum)
                const { BG } = contracts
                try {
                    setWaiting(true)

                    const contract = await new web3.eth.Contract(BG.ABI, BG.Address)
                    const premintFee = await contract.methods.presalePrice().call() * cantNFT
                    const pubmintFee = await contract.methods.publicPrice().call() * cantNFT
                    setDisabledMint(true)

                    const wallet = window.ethereum._state.accounts[0]
                    let totalM = totalMinted

                    if (Web3.utils.isAddress(wallet)) {
                        if (isPresale) {
                            axios.get(Config.apiURL + '/signature_whitelist/' + wallet + '/' + cantNFT)
                                .then(async res => {
                                    await contract.methods.mintPresale(
                                        cantNFT,
                                        false,
                                        res.data.nonce,
                                        res.data.validation
                                    ).send({ from: wallet, value: premintFee })

                                    setCantNFT(1)

                                    totalM = await contract.methods.getWlQtyMintedByGrowlie(window.ethereum._state.accounts[0]).call()
                                    const totalSup = await contract.methods.totalSupply().call()
                                    setTotalSupply(totalSup)

                                    setWaiting(false)
                                    setTotalMinted(totalM)
                                    setDisabledMint(parseInt(totalM) >= total)
                                    
                                }).catch(err => {
                                    setWaiting(false)
                                    setDisabledMint(parseInt(totalMinted) >= total)
                                })
                            } else {
                                await contract.methods.mint(
                                        cantNFT,
                                    ).send({ from: wallet, value: pubmintFee })

                                    setCantNFT(1)

                                    totalM = await contract.methods.getPubQtyMintedByGrowlie(window.ethereum._state.accounts[0]).call()
                                    const totalSup = await contract.methods.totalSupply().call()
                                    setTotalSupply(totalSup)

                                    setWaiting(false)
                                    setTotalMinted(totalM)
                                    setDisabledMint(parseInt(totalM) >= total)
                                    
                            }
                        }
                }
                catch (e) {
                    setWaiting(false)
                    setDisabledMint(parseInt(totalMinted) >= total)
                }
            }
        }
    }

    const mintFreeLootBox = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                const web3 = new Web3(window.ethereum)
                const { BG } = contracts

                try {
                    setWaiting(true)

                    const contract = await new web3.eth.Contract(BG.ABI, BG.Address)

                    const wallet = window.ethereum._state.accounts[0]

                    if (Web3.utils.isAddress(wallet)) {
                        axios.get(Config.apiURL + '/signature_freemint/' + wallet)
                            .then(async res => {
                                await contract.methods.mintPresale(
                                    1,
                                    true,
                                    res.data.nonce,
                                    res.data.validation
                                ).send({ from: wallet })
                                const free = await contract.methods.getFreeClaimQtyMintedByGrowlie(window.ethereum._state.accounts[0]).call()
                                const totalSup = await contract.methods.totalSupply().call()
                                setTotalSupply(totalSup)
                                setWaiting(false)
                                setFreeMinted(free)
                                setDisabledFreeMint(free)

                            }).catch(err => {
                                setWaiting(false)
                                setDisabledFreeMint(freeMinted)
                            })
                    }
                }
                catch (e) {
                    setWaiting(false)
                    setDisabledFreeMint(freeMinted)
                }
            }
        }
    }

    return (

        <div className="row banner" style={{ margin: 0, zIndex: 100 }}>
            <img src="assets/template/img/presale.png" className="img-fluid m-auto" alt="banner" />

            <div className="waiting">

                {(whitelist || !isPresale) &&
                    <div>
                        <div className="qty">
                            { isPresale ? 
                            <div>
                            <p className='text-waiting mint-text' style={{ fontSize: '25px', textTransform: 'none' }}>Welcome to the presale!</p>
                            <p className='text-waiting mb-3 mint-text' style={{ fontSize: '15px', textTransform: 'none', fontFamily: 'Averta' }} >
                                You are one of the 3,000 wlisters that will be able to mint up to 3 growlies. Good luck!
                            </p> 
                            </div>
                            :
                            <div>
                            <p className='text-waiting mint-text' style={{ fontSize: '25px', textTransform: 'none' }}>Welcome to the Public Sale!</p>
                            <p className='text-waiting mint-text' style={{ fontSize: '15px', textTransform: 'none', fontFamily: 'Averta' }} >
                            You can mint up to 10 growlies. LFG!!
                            </p> 
                            <p className='text-waiting mb-3 mint-text' style={{ fontSize: '15px', textTransform: 'none', fontFamily: 'Averta' }} >
                            Reveal Date is April 8th.
                            </p> 
                            </div>
                            }
                            <span className="plus bg-dark boxShadow" onClick={sumNFT}>+</span>
                            <input type="number" className="count" name="qty" value={cantNFT} min={1} max={total} readOnly />
                            <span className="minus bg-dark boxShadow" onClick={minNFT}>-</span>
                        </div>

                        <button className="mint boxShadow" disabled={disabledMint} onClick={mintLootBox}>MINT</button>
                    </div>
                }
                {freeMint &&
                    <div>
                        <hr color='blue' className="mintline" />
                        <button className="mint boxShadow" disabled={disabledFreeMint} onClick={mintFreeLootBox}>1 FREE MINT</button>
                    </div>

                }

            </div>
        </div>
    )
}

export default Mintt
