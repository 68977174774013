import React, { useEffect, useState } from 'react';
import {
    InputGroup, InputRightElement, InputLeftAddon, Input,
    useDisclosure,
} from "@chakra-ui/react";
import Modal from '../elements/modal';
import AlertDialogCustom from '../elements/alertDialog';
import axios from "axios";
import Config from '../../utils/Config.js';
import { useToast, Box, Spinner } from '@chakra-ui/react';
import * as XLSX from 'xlsx';
import { FaEraser } from 'react-icons/fa';
import contracts from '../../contracts';
import Web3 from 'web3';

import DataTable from 'react-data-table-component';
import FilterComponent from '../elements/FilterComponent'


export default function FreeMint() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [deleteElement, setDeleteElement] = useState({ name: '', id: '' })
    const [wallet, setWallet] = useState([]);
    const [walletAmount, setwalletAmount] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [message, setMessage] = useState(null);
    const [DataisLoaded, setDataisLoaded] = useState(false)
    const toast = useToast()

    const [Excel, setExcel] = useState('');
    var fileInput = React.createRef();

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = wallet.filter(
        item => item.wallet && item.wallet.toLowerCase().includes(filterText.toLowerCase()),
    );

    const subHeaderComponent = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);

    useEffect(() => {
        const ConfigHeader = {
            headers: {
                'Authorization': "JWT " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }

        axios.get(Config.apiURL + "/get_freemint/", ConfigHeader).then(res => {
            setWallet(res.data)
            setwalletAmount(res.data.length)
            setDataisLoaded(true)

        });

    }, []);

    useEffect(() => {
        if (errorMessage) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='red.500'>
                        {errorMessage}
                    </Box>
                ),
            })
            setTimeout(function () { setErrorMessage(null); }, 500)
        }
        if (message) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='green.500'>
                        {message}
                    </Box>
                ),
            })
            setTimeout(function () { setMessage(null); }, 500)
        }

    }, [errorMessage, message])

    const deleteElementF = (pk) => {
        const ConfigHeader = {
            headers: {
                'Authorization': "JWT " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
        axios.post(Config.apiURL + "/wallet_delete/" + pk, { free_mint: true, whitelist: false, member: false, prewhitelist: false }, ConfigHeader).then(() => {
            setMessage('Delete successfully')
            window.location.reload();
        });

    }

    const handleExcel = (event) => {
        const target = event.target
        const name = target.name
        let hojas = []
        if (name === 'file') {
            let reader = new FileReader()
            reader.readAsArrayBuffer(fileInput.current.files[0])
            reader.onloadend = (e) => {
                var data = new Uint8Array(e.target.result);
                var workbook = XLSX.read(data, { type: 'array' });

                workbook.SheetNames.forEach(function (sheetName) {
                    var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    XL_row_object = XL_row_object.filter(address => Web3.utils.isAddress(address.Wallet.replace(/\s+/g, '')))
                    .map(x => x.Wallet.replace(/\s+/g, '').toLowerCase())
                    console.log(XL_row_object)
                    
                    hojas.push({
                        data: XL_row_object,
                        sheetName
                    })
                })
                setExcel(hojas)

            }
        }

    }

    const populateWhitelist = () => {
        if (Excel === '') {
            setErrorMessage('You have to upload an excel file first');
        } else {
            const ConfigHeader = {
                headers: {
                    'Authorization': "JWT " + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            }

            var data = { walletlist: Excel }

            axios.post(Config.apiURL + "/add_list_freemint/", data, ConfigHeader).then(() => {
                setMessage('Added successfully')
                window.location.reload();
            }).catch(error => {
                setErrorMessage(error.message);
            });
        }
    }

    const addFreeMintUsers = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                // aqui va el array con los users
                try {
                    const users = wallet
                        .filter(address => Web3.utils.isAddress(address.wallet.replace(/\s+/g, '')))
                        .map(x => x.wallet)


                    const web3 = new Web3(window.ethereum)
                    const { Factory } = contracts

                    const contract = await new web3.eth.Contract(Factory.ABI, Factory.Address)
                    const addWhitelist = await contract.methods.freemintWhitelistedUsers(users).send({ from: window.ethereum._state.accounts[0] })

                    setMessage('FreeMint list added successfully to contract')
                    console.log(addWhitelist)
                }
                catch (e) {
                    setErrorMessage(e.message)
                }

            }
        }
    }

    const columns = [
        {
            name: 'Wallet',
            selector: row => row.wallet,
        },
        {
            name: 'Actions',
            cell: row => <a href="#" className="btn btn-danger btn-circle btn-sm" title="Delete" onClick={() => { setIsOpenDialog(true); setDeleteElement({ name: row.wallet, id: row.wallet }) }}>
                <FaEraser />
            </a>

        },
    ];

    return (
        <React.Fragment>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-6">
                        <h3 className="mb-2 text-gray-800">Wallets Free Mint</h3>
                    </div>
                    <div className="col-6" style={{ float: "right",  marginBottom: '20px' }}>
                        <InputGroup size='md'>
                            <InputLeftAddon children='Excel' />
                            <Input type="file" name="file" id="file" onChange={handleExcel} ref={fileInput} placeholder='Enter password'
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                            <InputRightElement width='4.5rem'>
                                <a href="#" className="btn btn-info btn-icon-split" style={{ float: "right" }} onClick={populateWhitelist}>
                                    <span className="text">Upload</span>
                                </a>
                            </InputRightElement>
                        </InputGroup>


                    </div>

                </div>

                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <a href="#" className="btn btn-success btn-icon-split mr-2" style={{ float: "right" }} onClick={onOpen} >
                            <span className="text">Add Wallet</span>
                        </a>
                    </div>

                    <div className="card-body">
                        {DataisLoaded ?
                            <DataTable
                                columns={columns}
                                data={filteredItems}
                                pagination
                                highlightOnHover
                                paginationRowsPerPageOptions={[50, 100, 150, 200]}
                                paginationResetDefaultPage={resetPaginationToggle}
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                                paginationPerPage={50}
                            />
                            : <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'

                            />
                        }

                    </div>

                </div>

            </div>
            <Modal onClose={onClose} isOpen={isOpen} freeMint={true} setErrorMessage={setErrorMessage}  setMessage={setMessage}/>
            <AlertDialogCustom isOpen={isOpenDialog} setIsOpen={setIsOpenDialog} accept={deleteElementF} element={deleteElement} />

        </React.Fragment>
    );
}
