import React, { useEffect, useState } from 'react';
import Header from '../components/Home/header'
import Footer from '../components/Home/footer'
import Main from '../components/Home/main'
import LoadingView from '../components/Home/loading';

export default function BattleGrowlies() {

    const [whitelistBD, setWhitelistBD] = useState(false);
    const [freeMintBD, setFreeMintBD] = useState(false);
    const [totalMinted, setTotalMinted] = useState(0)
    const [freeMinted, setFreeMinted] = useState(false)
    const [isPresale, setIsPresale] = useState(false)
    const [totalSupply, setTotalSupply] = useState(0)
    const [preWhitelist, setPreWhitelist] = useState(false);
    const [account, setAccount] = useState(null)
    const [loading, setLoading] = useState(true)
    const [active, setActive] = useState(false)

    useEffect(() => {
        document.getElementById("admin_css").setAttribute("disabled", "disabled");

        return () => {
            document.getElementById("main_css").setAttribute("disabled", "disabled");
        }

    }, []);


    return (
        <React.Fragment>
            <Header
                setActive={setActive}
                setTotalMinted={setTotalMinted}
                setFreeMinted={setFreeMinted}
                setIsPresale={setIsPresale}
                setPreWhitelist={setPreWhitelist}
                setAccount={setAccount}
                setWhitelistBD={setWhitelistBD}
                setFreeMintBD={setFreeMintBD}
                whitelist={whitelistBD}
                setLoading={setLoading}
                setTotalSupply={setTotalSupply}
            />
            {
                loading ? <LoadingView /> :
                    <Main
                        active={active}
                        totalMinted={totalMinted}
                        setTotalMinted={setTotalMinted}
                        freeMinted={freeMinted}
                        setFreeMinted={setFreeMinted}
                        isPresale={isPresale}
                        preWhitelist={preWhitelist}
                        setPreWhitelist={setPreWhitelist}
                        account={account}
                        whitelistBD={whitelistBD}
                        freeMintBD={freeMintBD}
                        setTotalSupply={setTotalSupply}
                        totalSupply={totalSupply}
                    />
            }
            <Footer />
        </React.Fragment>
    )

}
