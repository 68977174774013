import React, { useEffect, useState } from 'react';
import {
    useDisclosure,
} from "@chakra-ui/react";
import Modal from '../elements/modal';
import ModalTransfer from '../elements/modalTransfer'
import AlertDialogCustom from '../elements/alertDialog';
import axios from "axios";
import Config from '../../utils/Config.js';
import { useToast, Box, Spinner } from '@chakra-ui/react';
import { FaEraser } from 'react-icons/fa';
import contracts from '../../contracts';
import Web3 from 'web3';
import { ethers } from 'ethers';


export default function Member() {
    const [nftData, setNftData] = useState({})
    const [isMinted, setIsMinted] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [message, setMessage] = useState(null);
    const toast = useToast()
    const [balanceContract, setBalanceContract] = useState(0)
    const [nftId, setNftId] = useState('')

    const [Excel, setExcel] = useState('');
    var fileInput = React.createRef();

    useEffect(() => {
        if (errorMessage) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='red.500'>
                        {errorMessage}
                    </Box>
                ),
            })
            setTimeout(function () { setErrorMessage(null); }, 500)
        }
        if (message) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='green.500'>
                        {message}
                    </Box>
                ),
            })
            setTimeout(function () { setMessage(null); }, 500)
        }

    }, [errorMessage, message])

    const loadMetadata = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                try {
                    const web3 = new Web3(window.ethereum)
                    const { BG } = contracts

                    const contract = await new web3.eth.Contract(BG.ABI, BG.Address)
                    const totalSup = await contract.methods.totalSupply().call()
                    
                    console.log(totalSup)
                    
                    if (totalSup >= nftId)
                        setIsMinted(true)
                    else
                        setIsMinted(false)
                }
                catch (e) {
                    setErrorMessage(e.message)
                }
            }
        }

        const ConfigHeader = {
            headers: {
                'Authorization': "JWT " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
        axios.get(Config.apiURL+'/metadata/admin/'+nftId, ConfigHeader).then(
            res => {
                console.log(res.data)
                setNftData(res.data)
            }
        ).catch(
            err => setErrorMessage(err.message)
        )
    }

    const sendMetadata = (e) => {
        e.preventDefault()
        const ConfigHeader = {
            headers: {
                'Authorization': "JWT " + localStorage.getItem('access_token'),
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
        axios.post(Config.apiURL+'/metadata/'+nftId+'/edit/', nftData, ConfigHeader).then(
            res => console.log(res.data)
        ).catch(
            err => setErrorMessage(err.message)
        )
    }

    const onInputChange = (e) => {
        setNftData({
            ...nftData, 
            [e.target.name]: e.target.value
        })
    }

    const onAttributeChange = (e, index) => {
        let attr = nftData.attributes
        attr[index] = {...attr[index], value: e.target.value}
        setNftData({
            ...nftData,
            attributes: attr
        })
    }

    const onNftIdChange = (e) => {
        setNftId(e.target.value)
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="card shadow mb-4">
                    <div className="card-header  py-3">
                        <input className='discord' name='nftId' onChange={onNftIdChange} style={{ float: "right"}} />

                        <a href="#" className="btn btn-success btn-icon-split mr-2" style={{ float: "right" }} onClick={loadMetadata} >
                            <span className="text">Get NFT Metadata</span>
                        </a>
                    </div>

                    <div className="card-body">
                        <div>
                            {nftData.name ?
                                    <>
                                    <div className='row g-3 my-2'>
                                        <div className='col-md-6'>
                                            <h4>Minted: {isMinted ? 'Yes': 'No'}</h4>
                                        </div>
                                        <div className='col-md-6'>
                                            <label htmlFor='name' className='form-label'>Name</label>
                                            <input name='name' onChange={onInputChange} value={nftData.name} className='form-control' />
                                        </div>
                                        <div className='col-md-6'>
                                            <label htmlFor='description' className='form-label'>Description</label>
                                            <input name='description' onChange={onInputChange} value={nftData.description} className='form-control' />
                                        </div>
                                    </div>
                                    <div className='row g-3 my-2 py-2'>
                                        <div className='col-md-3'>
                                            <label htmlFor='background' className='form-label'>Background</label>
                                            <input name='background' onChange={(e) => onAttributeChange(e, 0)} className='form-control' value={nftData.attributes[0].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='skin' className='form-label'>Skin</label>
                                            <input name='skin' onChange={(e) => onAttributeChange(e, 1)} className='form-control' value={nftData.attributes[1].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='eyes' className='form-label'>Eyes</label>
                                            <input name='eyes' onChange={(e) => onAttributeChange(e, 2)} className='form-control' value={nftData.attributes[2].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='top' className='form-label'>Top</label>
                                            <input name='top' onChange={(e) => onAttributeChange(e, 3)} className='form-control' value={nftData.attributes[3].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='weapons' className='form-label'>Weapons</label>
                                            <input name='weapons' onChange={(e) => onAttributeChange(e, 4)} className='form-control' value={nftData.attributes[4].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='face' className='form-label'>Face</label>
                                            <input name='face' onChange={(e) => onAttributeChange(e, 5)} className='form-control' value={nftData.attributes[5].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='chest' className='form-label'>Chest</label>
                                            <input name='chest' onChange={(e) => onAttributeChange(e, 6)} className='form-control' value={nftData.attributes[6].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='firsLayer' className='form-label'>First Layer</label>
                                            <input name='firstLayer' onChange={(e) => onAttributeChange(e, 7)} className='form-control' value={nftData.attributes[7].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='neck' className='form-label'>Neck</label>
                                            <input name='neck' onChange={(e) => onAttributeChange(e, 8)} className='form-control' value={nftData.attributes[8].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='details' className='form-label'>Details</label>
                                            <input name='details' onChange={(e) => onAttributeChange(e, 9)} className='form-control' value={nftData.attributes[9].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='back' className='form-label'>Back</label>
                                            <input name='back' onChange={(e) => onAttributeChange(e, 10)} className='form-control' value={nftData.attributes[10].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='rank' className='form-label'>Rank</label>
                                            <input name='rank' onChange={(e) => onAttributeChange(e, 11)} className='form-control' value={nftData.attributes[11].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='resistance' className='form-label'>Total Resistance</label>
                                            <input name='resistance' onChange={(e) => onAttributeChange(e, 12)} className='form-control' value={nftData.attributes[12].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='armor' className='form-label'>Total Armor</label>
                                            <input name='armor' onChange={(e) => onAttributeChange(e, 13)} className='form-control' value={nftData.attributes[13].value} />
                                        </div>
                                        <div className='col-md-3'>
                                            <label htmlFor='damage' className='form-label'>Total Damage</label>
                                            <input name='damage' onChange={(e) => onAttributeChange(e, 14)} className='form-control' value={nftData.attributes[14].value} />
                                        </div>
                                    </div>
                                    <div className='card-header py-5'>
                                        <button href="#" className="btn btn-success btn-icon-split mr-2" style={{ float: "right" }} onClick={sendMetadata} >
                                            <span className="text">Update NFT Metadata</span>
                                        </button>
                                    </div>
                                    </> 
                                : <h2>Nothing to show</h2>
                            }
                        </div>
                    </div>

                </div>

            </div>

        </React.Fragment>
    );
}
