import React, { useEffect, useState } from 'react';
import Header from '../components/Home/header';
import Config from '../utils/Config.js';
import axios from "axios";
import { useToast, Box, Spinner } from '@chakra-ui/react';

export default function LoadingToken() {

    const [whitelistBD, setWhitelistBD] = useState(false);
    const [freeMintBD, setFreeMintBD] = useState(false);
    const [totalMinted, setTotalMinted] = useState(0)
    const [freeMinted, setFreeMinted] = useState(false)
    const [isPresale, setIsPresale] = useState(false)
    const [totalSupply, setTotalSupply] = useState(0)
    const [preWhitelist, setPreWhitelist] = useState(false);
    const [account, setAccount] = useState(null)
    const [loading, setLoading] = useState(true)
    const [active, setActive] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);

    const [idtoken, setIdToken] = useState(null)
    const [button, setButton] = useState('SEARCH')
    const toast = useToast()
    const [image, setImage] = useState("assets/loading/img/img_token.png")

    useEffect(() => {
        document.getElementById("main_css").setAttribute("disabled", "disabled");

        return () => {
            document.getElementById("loading_css").setAttribute("disabled", "disabled");
        }

    }, []);

    useEffect(() => {
        if (errorMessage) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='red.500'>
                        {errorMessage}
                    </Box>
                ),
            })
            setTimeout(function () { setErrorMessage(null); }, 500)
        }
      
    }, [errorMessage])

    const search = () => {
        setButton('LOADING')
        axios.get(Config.apiURL + "/metadata/" + idtoken).then((res) => {
            setButton('SEARCH')
            if(res.image)
            setImage(res.image)
            else
            setImage("assets/loading/img/img_token.png")
        }).catch(error => {
            setErrorMessage('Invalid Token Id');
            setButton('SEARCH')
        });
    }


    return (
        <React.Fragment>
            <header style={{ zIndex: 100 }}>

                {/* <div className="iso-marquee-linkwrap">
                    <div className="iso-marquee--long iso-marquee" style={{ "--tw": "145ch", "--ad": "28s" }}>
                        <span><p>Minting Date Coming Soon</p><p>Growlie-List (wl) Spots Still Available on Discord
                        </p><p>Minting Date Coming Soon</p><p>Minting Date Coming Soon</p><p>Growlie-List (wl) Spots Still Available on Discord
                            </p><p>Minting Date Coming Soon</p></span>
                    </div>
                </div> */}
                <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="#"><img src="assets/template/img/logoBG.png" className="logo" alt="logo" /></a>
                    <ul id="social" className="navbar-nav ml-auto" style={{ flexDirection: 'inherit' }}>
                        <li className="nav-item">
                            <a className="nav-link" href="https://twitter.com/BattleGrowlies" target="_blank"><i className="fa-brands fa-twitter" style={{ color: '#FA4EFC', fontSize: '40px' }}></i></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://discord.gg/battlegrowlies" target="_blank"><i className="fa-brands fa-discord" style={{ color: '#FA4EFC', fontSize: '40px' }}></i></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://opensea.io/collection/battlegrowliesofficial" target="_blank"><img src="assets/img/Logomark-Blue.svg" height="40" alt="opensea" style={{ width: '40px' }} /></a>
                        </li>
                    </ul>
                </nav>
            </header>
            <main id="token" className="container-fluid wrapper-relative">        
            <div className="row">
              <div className="one item1"><img src="assets/loading/img/pinzas1.png" /></div>
              <div className="one item2"><img src="assets/loading/img/pinzas2.png" /></div>
              <div className="one item3"><img src="assets/loading/img/pinza3.png" /></div>
              <div className="one item4"><img src="assets/loading/img/pinza4.png" /></div>
              <div className="col text-center pt-5 pb-5">
                
                  <input id="id_token" placeholder="Token Id"  value={idtoken} onChange={e => setIdToken(e.target.value)}/>
                  <div id="img_token"><img src={image} style={{display:'inline'}}/></div>
                  <button id="loading" className="ml-auto mr-auto" onClick={search}>{button}</button>
                
              </div>
            </div>          
      </main>
        </React.Fragment>
    )

}
