import React, { useEffect, useState } from 'react'


const WhitelistPage = () => {

    return (
        <div className="row banner" style={{ margin: 0, zIndex: 100 }}>
            <img src="assets/template/img/registroWL.png" className="img-fluid m-auto" alt="banner" />

            <div className="waiting">
                <div className="qty">
                    <img className='img-whitelist' src="assets/template/img/tooth.png" alt="tooth-menu" />
                    <p className='text-waiting' style={{ fontSize: '35px' }} >Congratulations!</p>
                    <p className='text-waiting' style={{ fontSize: '18px', textTransform: 'none', fontFamily: 'Averta' }} >You are now on the Growlie-list (wl)</p>
                    <p className='text-waiting mt-3' style={{ textTransform: 'none' }} >Pre-sale: April 2nd @ 12PM CST</p>
                </div>

            </div>
        </div>
    )
}

export default WhitelistPage