import React from 'react';
import Sidebar from '../components/Admin/Sidebar';
import Navbar from '../components/Admin/Navbar';
import User from '../components/Admin/User';
import { ChakraProvider } from '@chakra-ui/react';



export default function UserComponent() {

    return (
        <React.Fragment>
             <ChakraProvider>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">
                        <Navbar />
                        <User/>

                    </div>

                    <footer className="sticky-footer bg-white">
                        <div className="container my-auto">
                            <div className="copyright text-center my-auto">
                                <span>Copyright &copy; Softok2 2022</span>
                            </div>
                        </div>
                    </footer>
                </div>


            </div>
            </ChakraProvider>
        </React.Fragment>
    )
}
