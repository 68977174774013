import React from 'react';
import {
    Button, Text,
} from "@chakra-ui/react";

export default function ButtonCustom({color, onClick, text, style={}}) {
    return (
        <Button
            // bg="gray.800"
            bg={color}
            border="1px solid transparent"
            _hover={{
                border: "1px",
                borderStyle: "solid",
                borderColor: "blue.400",
                backgroundColor: "gray.700",
            }}
            borderRadius="xl"
            m="1px"
            px={3}
            height="38px"
            // onClick={logoutMetaMask}
            onClick={onClick}
        >
            <Text color="white" fontSize="md" fontWeight="medium" mr="2" style={style}>
{/* 
                {defaultAccount &&
                    `${defaultAccount.toString().slice(0, 6)}...${defaultAccount.toString().slice(
                        defaultAccount.toString().length - 4,
                        defaultAccount.toString().length
                    )}`} */}
                    {text}
            </Text>
        </Button>
    );
}
