import React from 'react';
import {
    Button, Input, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Form, Field } from 'formik';
import Config from '../../utils/Config.js';
import axios from "axios";
import Web3 from 'web3'



export default function ModalPass({ isOpen, onClose, setErrorMessage, user_id, setMessage }) {


    function validateNoEmpty(value) {
        let error;
        if (!value)
            error = 'Is required'

        return error
    }
    const validateConfirmPassword = (pass, value) => {

        let error = "";
        if (pass && value) {
            if (pass !== value) {
                error = "Password not matched";
            }
        }
        return error;
    };




    return (
        <React.Fragment>
            <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Wallet</ModalHeader>
                    <ModalCloseButton />
                    <Formik
                        initialValues={{ password: '', confirm_password: '' }}
                        onSubmit={(values, actions) => {
                            let data = {
                                new_password: values.password
                            }

                            const ConfigHeader = {
                                headers: {
                                    'Authorization': "JWT " + localStorage.getItem('access_token'),
                                    'Content-Type': 'application/json',
                                    'accept': 'application/json'
                                }
                            }
                            axios.patch(Config.apiURL + "/user/" + user_id + "/change_pass", data, ConfigHeader).then(() => {
                                actions.setSubmitting(false)
                                onClose()
                                setMessage('Changes successfully')
                            }).catch(error => {
                                actions.setSubmitting(false)
                                setErrorMessage(error.response.data.errors);
                            });
                        }}
                    >
                        {(props) => (
                            <Form>
                                <ModalBody>
                                    <Field name='password' validate={validateNoEmpty} >
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.password && form.touched.password} mt={5} isRequired>
                                                <FormLabel htmlFor='password' >Password</FormLabel>
                                                <Input {...field} id='password' type='password' />
                                                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name='confirm_password' validate={value => validateConfirmPassword(props.values.password, value)} >
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.confirm_password && form.touched.confirm_password} mt={5} isRequired>
                                                <FormLabel htmlFor='confirm_password' >Confirm Password</FormLabel>
                                                <Input {...field} id='confirm_password' type='password' />
                                                <FormErrorMessage>{form.errors.confirm_password}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>

                                    <Button
                                        colorScheme='teal'
                                        isLoading={props.isSubmitting}
                                        type='submit'
                                    >
                                        Submit
                                    </Button>
                                </ModalFooter>

                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </React.Fragment>
    );
}