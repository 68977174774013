import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import {
    useToast,  Box, Text, Button
} from "@chakra-ui/react";
import ButtonCustom from '../elements/button'
import Logout from '../elements/logout'
import jwt from 'jwt-decode'
import { useNavigate  } from "react-router-dom";
import axios from "axios";


export default function Navbar() {

    const [defaultAccount, setDefaultAccount] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [message, setMessage] = useState(null);
    const [userBalance, setUserBalance] = useState(null);
    const navigate  = useNavigate ()

    const toast = useToast()

    const [user, setUser]= useState('')

    useEffect(() => {
        const token = localStorage.getItem('access_token')
        if(token){
            const parseToken = jwt(token)
            setUser(parseToken.user) 
            connectWalletHandler(); 

        }else{
            navigate("/admin_login");
        }
      
     }, []);

    useEffect(() => {
        if (errorMessage) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='red.500'>
                        {errorMessage}
                    </Box>
                ),
            })
            setTimeout(function () { setErrorMessage(null); }, 500)
        }
        if (message) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='green.500'>
                        {message}
                    </Box>
                ),
            })
            setTimeout(function () { setMessage(null); }, 500)
        }

    }, [errorMessage, message])


    const connectWalletHandler = () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                window.ethereum.request({ method: 'eth_requestAccounts' })
                    .then(result => {
                        accountChangedHandler(result[0]);
                        getAccountBalance(result[0]);

                    })
                    .catch(error => {
                        setErrorMessage(error.message);

                    });
            } else {
                console.log('Install metamask');
                setErrorMessage('Please install MetaMask browser extension to interact');
            }


        } else {
            console.log('Install metamask');
            setErrorMessage('Please install MetaMask browser extension to interact');
        }
    }

    // update account, will cause component re-render
    const accountChangedHandler = (newAccount) => {
        setDefaultAccount(newAccount);
        getAccountBalance(newAccount.toString());

    }

    const getAccountBalance = (account) => {
        window.ethereum.request({ method: 'eth_getBalance', params: [account, 'latest'] })
            .then(balance => {
                setUserBalance(ethers.utils.formatEther(balance));
            })
            .catch(error => {
                setErrorMessage(error.message);
            });
    };

    const chainChangedHandler = () => {
        // reload the page to avoid any errors with chain change mid use of application
        window.location.reload();
    }


    // listen for account changes
    if (window.ethereum) {
        window.ethereum.on('accountsChanged', accountChangedHandler);

        window.ethereum.on('chainChanged', chainChangedHandler);
    }


    const logoutMetaMask = () => {
        window.userWalletAddress = null;
        setDefaultAccount(null);
        setUserBalance(null);
    }


    return (
        <React.Fragment>
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>

                <ul className="navbar-nav ml-auto">

                    <li>
                    {defaultAccount ? (
                            <div>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    background="#2F56C7"
                                    borderRadius="xl"
                                    py="0"
                                    marginTop="10px"
                                >
                                    <Box px="3">
                                        <Text color="white" fontSize="md" style={{marginBottom:'0'}}>
                                            {userBalance} ETH
                                        </Text>
                                    </Box>
                                    <ButtonCustom color="#0080FF" onClick={logoutMetaMask} style={{marginBottom:'0'}} text={defaultAccount &&
                                        `${defaultAccount.toString().slice(0, 6)}...${defaultAccount.toString().slice(
                                            defaultAccount.toString().length - 4,
                                            defaultAccount.toString().length
                                        )}`} />

                                </Box>
                                
                            </div>
                        ) : (
                           
                                <Button style={{backgroundColor:'#2F56C7',color:"white", marginTop:"10px"}} className="border-0" onClick={connectWalletHandler} >Connect wallet</Button>
                           
                        )
                        }
                    </li>

                    <div className="topbar-divider d-none d-sm-block"></div>

                    <li className="nav-item dropdown no-arrow"  style={{marginTop:'13px'}}>
                        
                            <span className="mr-2 d-none d-lg-inline text-gray-600 "> {user}</span>
                       
                     
                    </li>
                    <div className="topbar-divider d-none d-sm-block"></div>
                    <Logout/>

                </ul>

            </nav>
        </React.Fragment>
    );
}
