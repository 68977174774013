import React, { useEffect, useState } from 'react';
import {
    useDisclosure,
} from "@chakra-ui/react";
import Modal from '../elements/modal';
import ModalWhitelist from '../elements/modalWhitelist'
import AlertDialogCustom from '../elements/alertDialog';
import axios from "axios";
import Config from '../../utils/Config.js';
import {useToast, Box, Spinner } from '@chakra-ui/react'
import { FaEraser } from 'react-icons/fa';

import DataTable from 'react-data-table-component';
import FilterComponent from '../elements/FilterComponent'


export default function Wallet() {

    const { isOpen: isOpenModalAdd, onOpen: onOpenModalAdd, onClose: onCloseModalAdd } = useDisclosure()
    const { isOpen: isOpenModalWhitelist, onOpen: onOpenModalWhitelist, onClose: onCloseModalWhitelist } = useDisclosure()
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [deleteElement, setDeleteElement] = useState({ name: '', id: '' })
    const [wallet, setWallet] = useState([]);
    const [walletAmount, setwalletAmount] = useState(0);
    const [whitelisted, setWhitelisted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [message, setMessage] = useState(null);
    const [DataisLoaded, setDataisLoaded] = useState(false)
    const toast = useToast()

    const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const filteredItems = wallet.filter(
		item => item.wallet && (item.wallet.toLowerCase().includes(filterText.toLowerCase())) // || item.user_discord.toLowerCase().includes(filterText.toLowerCase()))
	)

	const subHeaderComponent = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);


    useEffect(() => {
        const ConfigHeader = {
            headers: {
                   'Authorization': "JWT " + localStorage.getItem('access_token'),
                   'Content-Type': 'application/json',
                   'accept': 'application/json'
               }
             }
        axios.get(Config.apiURL + "/get_prewhitelist/", ConfigHeader).then(res => {
            setWallet(res.data)
            setwalletAmount(res.data.length)
            setDataisLoaded(true)

            // res.data.forEach(data => {
            //     if (data.whitelist === true) {
            //         setWhitelisted(true)
            //      }
            // });

        });
    }, []);

    
    useEffect(()=>{
        if(errorMessage){
             toast({
            position: 'bottom-right',
            render: () => (
              <Box color='white' p={3} bg='red.500'>
                {errorMessage}
              </Box>
            ),
          })
          setTimeout(function(){ setErrorMessage(null); }, 500)
        }
        if (message) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='green.500'>
                        {message}
                    </Box>
                ),
            })
            setTimeout(function () { setMessage(null); }, 500)
        }
       
    },[errorMessage, message])

   
    const deleteElementF = (pk) => {
        const ConfigHeader = {
            headers: {
                   'Authorization': "JWT " + localStorage.getItem('access_token'),
                   'Content-Type': 'application/json',
                   'accept': 'application/json'
               }
             }
        axios.post(Config.apiURL + "/wallet_delete/" + pk,{free_mint:false, whitelist:false, member:false, prewhitelist:true}, ConfigHeader).then(() => {
            setMessage('Delete successfully')
            window.location.reload();
        });

    }

    const columns = [
        {
            id: 'discord',
            name: 'Discord handle',
            selector: row => row.user_discord,
        },
        {
            id: 'createDate',
            name: 'Create Date',
            selector: row => row.added_on,
            sortable: true,
        },
        {
            id: 'wallet',
            name: 'Wallet',
            selector: row => row.wallet,
        },
        {   
            id: 'actions',
            name: 'Actions',
            cell: row => <a href="#" className="btn btn-danger btn-circle btn-sm" title="Delete" onClick={() => { setIsOpenDialog(true); setDeleteElement({ name: row.wallet, id: row.wallet }) }}>
                <FaEraser />
            </a>

        },
    ];


    return (
        <React.Fragment>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-6">
                        <h3 className=" mb-2 text-gray-800">Wallets</h3>
                    </div>
                    
                        <div className="col-6" style={{ float: "right" }}>
                            <a href="#" className="btn btn-info btn-icon-split mb-2" style={{ float: "right" }} onClick={onOpenModalWhitelist}>
                                <span className="icon text-white-50">
                                    <i className="fas fa-list"></i>
                                </span>
                                <span className="text">Create Whitelist</span>
                            </a>
                        </div>
                    

                </div>

                <div className="card shadow mb-4">
                   
                    <div className="card-body">
                        
                            {DataisLoaded ?
                                 <DataTable
                                 columns={columns}
                                 data={filteredItems}
                                 pagination
                                 highlightOnHover
                                 paginationRowsPerPageOptions={[50, 100, 150, 200]}
                                 paginationResetDefaultPage={resetPaginationToggle}
                                 subHeader
                                 subHeaderComponent={subHeaderComponent}
                                 paginationPerPage={50}
                                 defaultSortFieldId="createDate"
                             />
                                : <Spinner
                                    thickness='4px'
                                    speed='0.65s'
                                    emptyColor='gray.200'
                                    color='blue.500'
                                    size='xl'
                            
                                />
                            }
                     
                    </div>

                </div>

            </div>
            <Modal onClose={onCloseModalAdd} isOpen={isOpenModalAdd} account={''} setPreWhitelist={(res) => { }} setErrorMessage={setErrorMessage}  setMessage={setMessage}/>
            <AlertDialogCustom isOpen={isOpenDialog} setIsOpen={setIsOpenDialog} accept={deleteElementF} element={deleteElement} />
            <ModalWhitelist amountWallet={walletAmount} onClose={onCloseModalWhitelist} isOpen={isOpenModalWhitelist} setErrorMessage={setErrorMessage} setMessage={setMessage}/>

        </React.Fragment>
    );
}
