import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import {
  Button, Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Box
} from "@chakra-ui/react";
import  axiosInstance  from "../../utils/axiosApi";
import { useNavigate  } from "react-router-dom";


export default function Login() {

  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const navigate  = useNavigate ()

  useEffect(() => {

    const cssFile = document.createElement("link");
    cssFile.href = "assets/sb-admin/css/sb-admin-2.min.css";// your css file path 
    cssFile.rel = "stylesheet";
    cssFile.id = "sd-admin-css";
    document.head.appendChild(cssFile);


}, []);

  useEffect(() => {
    if (errorMessage) {
      toast({
        position: 'bottom-right',
        render: () => (
          <Box color='white' p={3} bg='red.500'>
            {errorMessage}
          </Box>
        ),
      })
      console.log(errorMessage)
      setTimeout(function () { setErrorMessage(null); }, 500)
    }

  }, [errorMessage])

  
  function validateNoEmpty(value) {
    let error;
    if (!value)
      error = 'Is required'

    return error
  }


  return (
    <React.Fragment>
        <div className="container" >
          <div className="row justify-content-center">

            <div className="col-xl-6 col-lg-6 col-md-6">

              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">

                    <div className="col-lg-12">
                      <div className="p-5" style={{boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", backgroundColor: '#F3F3F3'}}>
                        <div className="text-center">
                        <div className="sidebar-brand-icon text-center" style={{display: 'flex', justifyContent: 'center',  alignItems: 'center'}}>
                    <img alt="Battle Growlies" width="50%" data-sticky-width="82" data-sticky-height="40" src="assets/img/logo.jpg" style={{marginLeft:'20px'}}/>
                    </div>
                        </div>

                        <Formik
                          initialValues={{ password: '', username: '' }}
                          onSubmit={(values, actions) => {
                            let data = {
                              password: values.password,
                              username: values.username,
                            }

                            actions.setSubmitting(true)
                            axiosInstance.post('token/obtain/', data).then(response => {
                              axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
                              localStorage.setItem('access_token', response.data.access);
                              localStorage.setItem('refresh_token', response.data.refresh);
                              actions.setSubmitting(false)
                              navigate("/whitelist");
                            }).catch(error => {
                              actions.setSubmitting(false)
                              setErrorMessage('Credenciales inválidas');

                            });

                          }}
                        >
                          {(props) => (
                            <Form>

                              <Field name='username' validate={validateNoEmpty} >
                                {({ field, form }) => (
                                  <FormControl isInvalid={form.errors.username && form.touched.username} mt={5} isRequired>
                                    <FormLabel htmlFor='username' >username</FormLabel>
                                    <Input {...field} id='username' placeholder='username' />
                                    <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <Field name='password' validate={validateNoEmpty} >
                                {({ field, form }) => (
                                  <FormControl isInvalid={form.errors.password && form.touched.password} mt={5} isRequired>
                                    <FormLabel htmlFor='password' >password</FormLabel>
                                    <Input {...field} id='password' placeholder='password' type="password" />
                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>

                              <Button
                                colorScheme='blue'
                                isLoading={props.isSubmitting}
                                type='submit'
                                mt={5}
                                style={{ width: '100%' }}
                              >
                                Login
                              </Button>

                            </Form>
                          )}
                        </Formik>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
     
    </React.Fragment>
  )
}
