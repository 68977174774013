import React, { useEffect, useState } from 'react';
import Config from '../../utils/Config.js';
import axios from "axios";
import { ethers } from 'ethers';
import {
    useToast, Box, Text, useDisclosure
} from "@chakra-ui/react";
import ButtonCustom from '../elements/button'
import contracts from '../../contracts'
import Web3 from 'web3'

export default function Header(
    {
        whitelist,
        freemint,
        setTotalMinted,
        setFreeMinted,
        setActive,
        setIsPresale,
        setPreWhitelist,
        setAccount,
        setWhitelistBD,
        setFreeMintBD,
        setLoading,
        setTotalSupply
    }) {

    const [errorMessage, setErrorMessage] = useState(null);
    const [message, setMessage] = useState(null);
    const [defaultAccount, setDefaultAccount] = useState(null);
    const [userBalance, setUserBalance] = useState(null);

    const toast = useToast()


    useEffect(() => {
        connectWalletHandler();
    }, []);

    useEffect(() => {
        if (errorMessage) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='red.500'>
                        {errorMessage}
                    </Box>
                ),
            })
            setTimeout(function () { setErrorMessage(null); }, 500)
        }

    }, [errorMessage])

    useEffect(() => {

        if (message) {
            toast({
                position: 'bottom-right',
                render: () => (
                    <Box color='white' p={3} bg='green.500'>
                        {message}
                    </Box>
                ),
            })
            setTimeout(function () { setMessage(null); }, 500)
        }

    }, [message])

    useEffect(async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                const web3 = new Web3(window.ethereum)
                const { BG } = contracts
                const contract = await new web3.eth.Contract(BG.ABI, BG.Address)

                const contractActive = await contract.methods.isPaused().call()

                setActive(!contractActive)
            }
        }
    }, [])


    const connectWalletHandler = () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                window.ethereum.request({ method: 'eth_requestAccounts' })
                    .then(result => {
                        accountChangedHandler(result[0]);
                        getAccountBalance(result[0]);

                    })
                    .catch(error => {
                        setLoading(false)
                        setErrorMessage(error.message);

                    });
            } else {
                setLoading(false)
                setErrorMessage('Please install MetaMask browser extension to interact');
            }


        } else {
            setLoading(false)
            setErrorMessage('Please install MetaMask browser extension to interact');
        }
    }

    // // update account, will cause component re-render
    const accountChangedHandler = async (newAccount) => {
        setDefaultAccount(newAccount);

        getAccountBalance(newAccount.toString());
        setAccount(newAccount)

        setLoading(true)

            if (window.ethereum) {
                if (window.ethereum.isMetaMask) {
                    try {
                        const web3 = new Web3(window.ethereum)
                        const { BG } = contracts
                        const contract = await new web3.eth.Contract(BG.ABI, BG.Address)

                        const contractActive = await contract.methods.isPaused().call()
                        setActive(!contractActive)

                        const presale = await contract.methods.isPublicSale().call()
                        setIsPresale(!presale)

                        if (!presale) {

                            axios.get(Config.apiURL + "/wallet/" + newAccount.toString().toLowerCase()).then(async (res) => {

                                const datos = res.data

                                if (datos.prewhitelist) {
                                    setPreWhitelist(datos.prewhitelist)
                                }
                                if (datos.whitelist) {
                                    setWhitelistBD(datos.whitelist)
                                    const total = await contract.methods.getWlQtyMintedByGrowlie(window.ethereum._state.accounts[0]).call()
                                    setTotalMinted(total)
                                }
                                if (datos.free_mint) {
                                    setFreeMintBD(datos.free_mint)
                                    const free = await contract.methods.getFreeClaimQtyMintedByGrowlie(window.ethereum._state.accounts[0]).call()
                                    setFreeMinted(free)
                                }

                                const totalSup = await contract.methods.totalSupply().call()
                                setTotalSupply(totalSup)

                                setLoading(false)


                            }).catch(error => {
                                setPreWhitelist(false)
                                setWhitelistBD(false)
                                setFreeMintBD(false)
                                setLoading(false)
                                setErrorMessage(error.response.data.errors);
                            });

                        } else {
                            const total = await contract.methods.getPubQtyMintedByGrowlie(window.ethereum._state.accounts[0]).call()
                            const totalSup = await contract.methods.totalSupply().call()
                            setTotalSupply(totalSup)
                            setTotalMinted(total)
                            setLoading(false)
                        }
                    } catch (e) {
                        setLoading(false)
                    }
                }else{
                    setLoading(false)
                }
            }else{
                setLoading(false)
            }


    }

    const getAccountBalance = (account) => {
        window.ethereum.request({ method: 'eth_getBalance', params: [account, 'latest'] })
            .then(balance => {
                setUserBalance(ethers.utils.formatEther(balance));
            })
            .catch(error => {
                setErrorMessage(error.message);
            });
    };

    const chainChangedHandler = () => {
        // reload the page to avoid any errors with chain change mid use of application
        window.location.reload();
    }


    // listen for account changes
    if (window.ethereum) {
        window.ethereum.on('accountsChanged', accountChangedHandler);
        window.ethereum.on('chainChanged', chainChangedHandler);
    }


    const logoutMetaMask = () => {
        window.userWalletAddress = null;
        setDefaultAccount(null);
        setUserBalance(null);
        setWhitelistBD(false);
        setFreeMintBD(false);
        setLoading(false);
        setTotalMinted(0);
        setFreeMinted(false);
        setActive(false);
        setIsPresale(false);
        setPreWhitelist(false);
        setAccount(null);
    }



    return (
        <React.Fragment>
            <header style={{ zIndex: 100 }}>

                {/* <div className="iso-marquee-linkwrap">
                    <div className="iso-marquee--long iso-marquee" style={{ "--tw": "145ch", "--ad": "28s" }}>
                        <span><p>Minting Date Coming Soon</p><p>Growlie-List (wl) Spots Still Available on Discord
                        </p><p>Minting Date Coming Soon</p><p>Minting Date Coming Soon</p><p>Growlie-List (wl) Spots Still Available on Discord
                            </p><p>Minting Date Coming Soon</p></span>
                    </div>
                </div> */}
                <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="#"><img src="assets/template/img/logoBG.png" className="logo" alt="logo" /></a>
                    <ul id="social" className="navbar-nav ml-auto" style={{ flexDirection: 'inherit' }}>
                        <li className="nav-item">
                            <a className="nav-link" href="https://twitter.com/BattleGrowlies" target="_blank"><i className="fa-brands fa-twitter" style={{ color: '#FA4EFC', fontSize: '40px' }}></i></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://discord.gg/battlegrowlies" target="_blank"><i className="fa-brands fa-discord" style={{ color: '#FA4EFC', fontSize: '40px' }}></i></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://opensea.io/collection/battlegrowliesofficial" target="_blank"><img src="assets/img/Logomark-Blue.svg" height="40" alt="opensea" style={{ width: '40px' }} /></a>
                        </li>
                    </ul>
                    <ul className="navbar-nav">

                        {defaultAccount ? (
                            <div>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    background="#d53ff5"
                                    borderRadius="xl"
                                    py="0"
                                    marginTop="3px"
                                >
                                    <Box px="3">
                                        <Text color="white" fontSize="md">
                                            {userBalance} ETH
                                        </Text>
                                    </Box>
                                    <ButtonCustom color="#FA4EFC" onClick={logoutMetaMask} text={defaultAccount &&
                                        `${defaultAccount.toString().slice(0, 6)}...${defaultAccount.toString().slice(
                                            defaultAccount.toString().length - 4,
                                            defaultAccount.toString().length
                                        )}`} />

                                </Box>
                                {/* {!preWhitelist && (
                                    <div style={{ float: 'right' }}>
                                        <ButtonCustom color="#d53ff5" onClick={whitelistAdd} text="Add Whitelist" />
                                    </div>
                                )} */}

                            </div>
                        ) : (
                            <li className="nav-item">
                                <button id="wallet" className="border-0" onClick={connectWalletHandler}  >Connect wallet</button>
                            </li>

                        )
                        }

                        {whitelist &&
                            <li className="nav-item">
                                <img className="tooth-menu" src="assets/template/img/tooth.png" width="40" alt="tooth-menu" />
                            </li>
                        } </ul>


                </nav>
            </header>
        </React.Fragment>
    )

}
