import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import Login from '../components/Admin/Login' 

export default function LoginComponent() {

  return (
    <React.Fragment>
      <ChakraProvider>
      <Login/>
      </ChakraProvider>
    </React.Fragment>
  )
}
