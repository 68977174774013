import React from 'react';
import {
    Button, Input,  Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Form, Field } from 'formik';
import Config from '../../utils/Config.js';
import axios from "axios";
import Web3 from 'web3'



export default function ModalCustom({ isOpen, onClose, setErrorMessage, freeMint, member=false, setMessage}) {

    function validateNoEmpty(value) {
        let error;
        if (!value)
            error = 'Is required'
        
        if(!Web3.utils.isAddress(value.replace(/\s+/g, '')))        
            error = 'Invalid address'

        return error
    }



    return (
        <React.Fragment>
            <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Wallet</ModalHeader>
                    <ModalCloseButton />
                    <Formik
                        initialValues={{ wallet: '' }}
                        onSubmit={(values, actions) => {
                            let data = {
                                wallet: values.wallet.replace(/\s+/g, '').toLowerCase()
                            }
                            if(freeMint)
                                data['free_mint'] = true
                            else if(member)    
                                data['member'] = true
                            else   
                                data['whitelist'] = true 

                            console.log(data)    

                            const ConfigHeader = {
                                headers: {
                                        'Authorization': "JWT " + localStorage.getItem('access_token'),
                                        'Content-Type': 'application/json',
                                        'accept': 'application/json'
                                    }
                                    }
                            axios.post(Config.apiURL + "/wallet_add/", data, ConfigHeader).then(() => {
                                actions.setSubmitting(false)
                                onClose()
                                setMessage('Added successfully')
                                window.location.reload();
                            }).catch(error => {
                                actions.setSubmitting(false)
                                setErrorMessage(error.response.data.errors);
                            });
                        }}
                    >
                        {(props) => (
                            <Form>
                                <ModalBody>
{/* 
                                    <Field name='email' validate={validateEmail}>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.email && form.touched.email} mt={5} isRequired>
                                                <FormLabel htmlFor='email'>Email</FormLabel>
                                                <Input {...field} id='email' placeholder='email' />
                                                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field> */}


                                    <Field name='wallet' validate={validateNoEmpty} >
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.wallet && form.touched.wallet} mt={5} isRequired>
                                                <FormLabel htmlFor='wallet' >Wallet</FormLabel>
                                                <Input {...field} id='walletA' placeholder='wallet' />
                                                <FormErrorMessage>{form.errors.wallet}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>

                                    <Button
                                        colorScheme='teal'
                                        isLoading={props.isSubmitting}
                                        type='submit'
                                    >
                                        Submit
                                    </Button>
                                </ModalFooter>

                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </React.Fragment>
    );
}