import React from 'react';
import axiosInstance from "../../utils/axiosApi";
import { useNavigate  } from "react-router-dom";

export default function Logout() {

    const navigate  = useNavigate ()

    const handleLogout = async () => {
        try {
            const response = await axiosInstance.post('/blacklist/', {
                "refresh_token": localStorage.getItem("refresh_token")
            });
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            axiosInstance.defaults.headers['Authorization'] = null;
            navigate("/admin_login");
        }
        catch (e) {
            console.log(e);
        }
    };


    return (
        <div className="site" style={{marginTop:'13px'}}>
           
                <button onClick={handleLogout}>Logout</button>
            
        </div>
    )

}