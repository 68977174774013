import React from 'react';
import {
    Button, Input, Text, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from "@chakra-ui/react";
import { Formik, Form, Field } from 'formik';
import Config from '../../utils/Config.js';
import axios from "axios";


export default function ModalWhitelist({ isOpen, onClose, amountWallet, setErrorMessage, setMessage }) {

    function validateNoEmpty(value) {
        let error;
        if (!value)
            error = 'Is required'


        if (parseInt(value) > amountWallet) {
            error = 'The number of wallet must be less than or equal to ' + amountWallet;
        }


        return error
    }


    return (
        <React.Fragment>
            <Modal isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create Whitelist</ModalHeader>
                    <ModalCloseButton />
                    <Formik
                        initialValues={{ amount: '', }}
                        onSubmit={(values, actions) => {
                            const ConfigHeader = {
                                headers: {
                                       'Authorization': "JWT " + localStorage.getItem('access_token'),
                                       'Content-Type': 'application/json',
                                       'accept': 'application/json'
                                   }
                                 }
                            axios.post(Config.apiURL + "/set_random_whitelist/" + values.amount,'' ,ConfigHeader).then((result) => {
                                actions.setSubmitting(false)
                                onClose()
                                setMessage('Added Successfully')
                            }).catch(error => {
                                actions.setSubmitting(false)
                                setErrorMessage(error.message);
                            });
                        }}
                    >
                        {(props) => (
                            <Form>
                                <ModalBody>

                                    <Field name='amount' validate={validateNoEmpty}>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.amount && form.touched.amount} mt={5} isRequired>
                                                <FormLabel htmlFor='amount'>Amount</FormLabel>
                                                <Input {...field} id='amount' placeholder='amount' />
                                                <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>


                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>

                                    <Button
                                        colorScheme='teal'
                                        isLoading={props.isSubmitting}
                                        type='submit'
                                    >
                                        Submit
                                    </Button>
                                </ModalFooter>

                            </Form>
                        )}
                    </Formik>
                </ModalContent>
            </Modal>
        </React.Fragment>
    );
}